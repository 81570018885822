<div *ngIf="nationalDealerPerformanceFinancialServicesjsonObj?.datasource">
  <div
    [ngClass]="nationalDealerPerformanceFinancialServicesjsonObj?.datasource?.length === 0 ? '':'dxfw-content-table-container'">
   <div class = "image-container">
    <div *ngIf="nationalDealerPerformanceFinancialServicesjsonObj?.datasource?.length > 0 ">
      <img class="logo-left" [src] = "imgPath" alt ="image">
    </div>
    <div *ngIf="nationalDealerPerformanceFinancialServicesjsonObj?.datasource?.length > 0 && hierarchy===10">
      <img class="logo-right" [src] = "rightLogoPath" alt ="image">
    </div>
  </div>
    <p *ngIf ="nationalDealerPerformanceFinancialServicesjsonObj?.datasource?.length>0" class="header">{{reportTitleToDisplay}}</p>
    <div *ngFor="let currdataSource of nationalDealerPerformanceFinancialServicesjsonObj?.datasource; let k=index">
      <div class="dfxw-part-table" *ngFor="let datasource of currdataSource ; let  i = index ">
        <app-multi-header-table [tableIndex]="k" [multiHeaderTableData]='datasource' [reportId]="reportId">
        </app-multi-header-table>
      </div>
    </div>
  </div>
  <div class="dxfw-no-data" *ngIf="nationalDealerPerformanceFinancialServicesjsonObj?.datasource?.length === 0">
    {{noDataToDisplayMsg}}</div>
  <div class="dxfw-no-data" *ngIf="selectedFilter?.length === 0">Please select the filters...</div>

</div>