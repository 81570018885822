<div class = "dspm_monthly_summary_report">
  <div *ngIf="dspmMonthlySummaryReportjsonObj?.datasource">
      <div class="dxfw-table-heading" *ngIf="dspmMonthlySummaryReportjsonObj?.datasource?.length > 0">{{reportTitleToDisplay}}{{monthYear}}</div>
      <div [ngClass]="dspmMonthlySummaryReportjsonObj?.datasource?.length === 0 ? '':'dxfw-content-table-container'">
        <div *ngFor="let currdataSource of dspmMonthlySummaryReportjsonObj?.datasource; let  i = index ">
          <div class="dfxw-part-table" *ngFor="let datasource of currdataSource ">
            <app-multi-header-table [tableIndex]="i" [multiHeaderTableData]='datasource' [reportId]="reportId">
            </app-multi-header-table>
          </div>
        </div>
      </div>
      <div class="dxfw-no-data" *ngIf="dspmMonthlySummaryReportjsonObj?.datasource?.length === 0 && selectedFilteredValues?.length != 0">{{noDataToDisplayMsg}}</div>
      <div class="dxfw-no-data" *ngIf="selectedFilteredValues?.length === 0">Please select the filters...</div>
    </div>
  </div>