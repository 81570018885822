import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin, Observable, Subscription } from "rxjs";
import { REPORT_MSGS, reportUrlType } from "src/app/constants/constants";
import { SpinnerService } from "src/app/services/spinner.service";
import { ToastService } from 'src/app/services/toast.service';
import { HIERARCHY_TYPE ,reportId} from 'src/app/constants/constants';
import { CustomReportsApiService } from "../../services/api/custom-report-api.service";
import { CustomReportsFiltersService } from "../../services/custom-report-filters.service";
import { CustomReportsService } from "../../services/custom-report.service";
import { getImageFromBase64, getMonthAndYear, getObjFromFilteredArray } from "../../utils/common-utils";
import * as _ from 'lodash';

@Component({
  selector: 'app-national-composite-new-used-dept-analysis',
  templateUrl: './national-composite-new-used-dept-analysis.component.html',
  styleUrls: ['./national-composite-new-used-dept-analysis.component.scss']
})
export class NationalCompositeNewUsedDeptAnalysisComponent implements OnInit {
  public nationalCompositeNewUsedDeptAnalysisjsonObj;
  public subscription: Subscription = new Subscription();
  public sources: Observable<any>[] = [];
  public reportId: number;
  public reportTitleToDisplay: string;
  public noDataToDisplayMsg: string;
  selectedFilteredValues: any = [];
  public obj = Object.values;
  reportTitle: string = '';
  public companyCode:number;
  public imgPath: string;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private customerReportService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    const reportList = JSON.parse(localStorage.getItem('report-list'));
    this.reportTitle = reportList?.[this.reportId]?.name;
    this.getNationalCompositeNewUsedDeptAnalysisReport();
  }
  ngOnInit(): void {
    this.subscribeReportData();
  }

  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }



  public getTabDataSource(selectedFilter: any) {
    const obj = getObjFromFilteredArray(selectedFilter);
    this.companyCode = obj['company'].id;
    const dateObj = getMonthAndYear(obj['monthYear']);
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      company_id: obj['company'].id,
      view_id: 'dpr_composite'
    }
    if(this.reportId === reportId.NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT){
      this.sources = [this.customReportsApiService.getNationalCompositeNewUsedDeptAnalysisData(requestObj).pipe(this.customerReportService.handleError()?.bind(this))
      ]
    }else{
    this.sources = [this.customReportsApiService.getTFSNationalCompositeNewUsedDeptAnalysisData(requestObj).pipe(this.customerReportService.handleError()?.bind(this))
    ]
   }
    return this.sources;
  }

  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (selectedFilter) => {
          if (selectedFilter?.length > 0) {
            this.selectedFilteredValues = selectedFilter;
            this.spinnerService.displaySpinner();
            this.mapResponse(selectedFilter);
          }
        }, error: (error) => {
          console.error('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.customerReportService.setPdfExcelIcons(false);
    this.subscription.unsubscribe();
  }

  public getNationalCompositeNewUsedDeptAnalysisReport() {
    this.nationalCompositeNewUsedDeptAnalysisjsonObj = {
      multiDataSources: true,
      datasource: []

    }
  }

  public async mapResponse(selectedFilter: any) {
    let currentTableResponse = [];
    await this.getTabDataSource(selectedFilter);
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        if (resp.length > 0 && resp[0].status === 200 && resp[0].body) {
        this.customerReportService.setPdfExcelIcons(true);
         this.imgPath =  getImageFromBase64(this.companyCode);
          let array = ['new', 'used', 'combined'];
          array.forEach(element => {
            let reportData = resp[0].body?.[element]?.[0]?.reportData;
            if (reportData?.length > 0) {
              const headers = this.getHeadersAccordingly(reportData[0].sectionData[0], resp[0].body[element][0].reportHeaderData);
              currentTableResponse = [...currentTableResponse, ...this.assignReportData(reportData, element.toUpperCase(), headers)];
            } else {
              this.noDataToDisplayMsg = REPORT_MSGS.NO_RECORDS_FOUND;
            }

          });
        }
        this.nationalCompositeNewUsedDeptAnalysisjsonObj.datasource = currentTableResponse;
        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }

  public assignReportData(reportData, mainHeader, headers) {
    let reportName;
    let colSpanValue;
    this.selectedFilteredValues.forEach(element => {
      if (element.key == 'company') {
        reportName = element.value.name;
      }
    });
    if (this.reportId === reportId.NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT) {
      if (reportName === 'Lexus') {
        colSpanValue = 8;
      } else {
        colSpanValue = 13;
      }
    } else if (this.reportId === reportId.TFS_NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT){
       colSpanValue = 6;
    }
    let partDepReportHeader = { NationalDpr: [{ [mainHeader]: colSpanValue }] };
    let dataArr = [];
    reportData.forEach((ele, index) => {
      if (ele.sectionData?.length > 0) {
        const obj = {
          'datasourceName': `nationalDpr ${index}`,
          'tableData': ele.sectionData,
          'sectionTitle': ele.SECTIONTITLE,
          'sectionId': ele.SECTIONNO,
          'removeLineBreak': true,
          'showLineNumbers': true,
          'columnHeaderName': this.obj(headers),
          'dateFormattingColumns': 'all'

        };
        if (index === 0) {
          obj['tableHeader'] = partDepReportHeader.NationalDpr;
          obj["tableHeaderClass"] = 'dxfw-national-dpr-header'
        }
        dataArr.push(obj)
      }
    });
    return dataArr;
  }


  getHeadersAccordingly(sectionData, reportHeaderData) {
    const hideColumns = ['ACCTSEQNO', 'ACCT_NO', 'SECTIONID', 'SECTIONNO', 'SECTIONTITLE'];
    const keys = Object.keys(sectionData)?.filter(((ele: any) => {
      if (!hideColumns.includes(ele)) {
        return ele;
      }
    }));
    keys.forEach(key => {
      reportHeaderData.filter(obj => {
        if (key.includes(obj.REGION_CODE)) {
          let index = keys.findIndex(x => x === key);
          if (index != -1) {
            keys[index] = obj.REGION_ALPHA_CODE.trim();
          }
        }
      });

    })
    keys.unshift(' ');
    return keys;
  }


  public createPayload(searchMonth, region, district, searchYear, dealer, viewId) {
    const requestObj = {
      month: searchMonth,
      region_no: region,
      district_cd: district,
      year: searchYear,
      dealer_no: dealer,
      view_id: viewId
    }
    return requestObj;
  }

}
