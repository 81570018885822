<div *ngIf="dealerPerformaceNewUsedCombinedFsFinjsonObj?.datasource">
  <div
    [ngClass]="dealerPerformaceNewUsedCombinedFsFinjsonObj?.datasource?.length === 0 ? '':'dxfw-content-table-container'">
    <div class="image-container">
      <div *ngIf="dealerPerformaceNewUsedCombinedFsFinjsonObj?.datasource?.length > 0 ">
        <img class="logo-left" [src]="imgPath" alt="image">
      </div>
      <div *ngIf="dealerPerformaceNewUsedCombinedFsFinjsonObj?.datasource?.length > 0 && hierarchy===10">
        <img class="logo-right" [src]="rightLogoPath" alt="image">
      </div>
    </div>
    <p *ngIf="dealerPerformaceNewUsedCombinedFsFinjsonObj?.datasource?.length>0" class="header">{{reportTitleToDisplay}}
    </p>
    <div *ngFor="let currdataSource of dealerPerformaceNewUsedCombinedFsFinjsonObj?.datasource; let  i = index">
      <div class="dfxw-part-table">
        <app-multi-header-table [tableIndex]="i" [multiHeaderTableData]='currdataSource' [reportId]="reportId">
        </app-multi-header-table>
      </div>
    </div>
  </div>
  <div class="dxfw-no-data"
    *ngIf="dealerPerformaceNewUsedCombinedFsFinjsonObj?.datasource && selectedFilteredValues?.length != 0">
    {{noDataToDisplayMsg}}</div>
  <div class="dxfw-no-data" *ngIf="selectedFilteredValues?.length === 0">Please select the filters...</div>
</div>