import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin, Observable, Subscription } from "rxjs";
import { REPORT_MSGS, reportUrlType } from "src/app/constants/constants";
import { SpinnerService } from "src/app/services/spinner.service";
import { ToastService } from 'src/app/services/toast.service';
import { CompanyTypeMock } from "../../mock/mock";
import { HIERARCHY_TYPE } from 'src/app/constants/constants';
import { CustomReportsApiService } from "../../services/api/custom-report-api.service";
import { CustomReportsFiltersService } from "../../services/custom-report-filters.service";
import { CustomReportsService } from "../../services/custom-report.service";
import { getMonthAndYear, getObjFromFilteredArray } from "../../utils/common-utils";

@Component({
  selector: 'app-parts-service-sales-analysis',
  templateUrl: './parts-service-sales-analysis.component.html',
  styleUrls: ['./parts-service-sales-analysis.component.scss']
})
export class PartsServiceSalesAnalysisComponent implements OnInit {
  public partsServiceSalesAnalysisjsonObj;
  public subscription: Subscription = new Subscription();
  public sources: Observable<any>[] = [];
  public reportId: number;
  public reportTitleToDisplay: string;
  public noDataToDisplayMsg: string;
  selectedFilteredValues: any = [];
  reportTitle: string;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private customerReportService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();

    this.reportId = reportUrlType[reportTypeNameFromUrl];
    const reportList = JSON.parse(localStorage.getItem('report-list'));
    this.reportTitle = reportList?.[this.reportId]?.name;
    this.getPartsServiceAndSalesAnalysis();
  }
  ngOnInit(): void {
    this.subscribeReportData();
  }

  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }

  public getTabDataSource(selectedFilter: any) {
    const obj = getObjFromFilteredArray(selectedFilter);
    const dateObj = getMonthAndYear(obj['monthYear']);
    const viewId = this.getViewId(obj['company']);
    let requestObj;
    const filterObj = obj['dealer'];
    if (obj['hierarchy']?.id === HIERARCHY_TYPE.DEALER) {   // 10 - Dealer
      requestObj = this.createPayload(dateObj.searchMonth, null, null, dateObj.searchYear, filterObj.MEMBER_CODE, viewId);
      this.reportTitleToDisplay = 'DISTRICT: ' + filterObj.DISTRICT_CODE + ' DEALER: ' + filterObj.MEMBER_CODE + ' - ' + filterObj.MEMBER_NAME;
    } else if (obj['hierarchy']?.id ===  HIERARCHY_TYPE.DISTRICT) {  // 11 - District
      this.reportTitleToDisplay = filterObj.DISTRICT_CODE + ' - ' + filterObj.MEMBER_NAME;
      requestObj = this.createPayload(dateObj.searchMonth, filterObj.REGION_CODE, filterObj.DISTRICT_CODE, dateObj.searchYear, null, viewId);
    } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.REGION) {  // 12 - Region
      this.reportTitleToDisplay = 'REGION: ' + filterObj.REGION_CODE;
      requestObj = this.createPayload(dateObj.searchMonth, filterObj.REGION_CODE, null, dateObj.searchYear, null, viewId);
    } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.NATIONAL) {   // 13 - National
      requestObj = this.createPayload(dateObj.searchMonth, null, null, dateObj.searchYear, filterObj.MEMBER_CODE, viewId);
    }
    this.sources = [this.customReportsApiService.getPartsServiceAndSalesAnalysisData(this.reportId, requestObj).pipe(this.customerReportService.handleError()?.bind(this))
    ]
    return this.sources;
  }

  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (selectedFilter) => {
          if (selectedFilter?.length > 0) {
            this.selectedFilteredValues = selectedFilter;
            this.spinnerService.displaySpinner();
            this.mapResponse(selectedFilter);
          }
        }, error: (error) => {
          console.error('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription.unsubscribe();
    this.customerReportService.setPdfExcelIcons(false);
  }

  public getPartsServiceAndSalesAnalysis() {
    this.partsServiceSalesAnalysisjsonObj = {
      multiDataSources: true,
      datasource: []

    }
  }

  public async mapResponse(selectedFilter: any) {
    let currentTableResponse = [];
    await this.getTabDataSource(selectedFilter);
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        if (resp.length > 0 && resp[0].status === 200) {
          this.customerReportService.setPdfExcelIcons(true);
          let reportData = resp[0]?.body[0]?.reportData;
          if (reportData?.length > 0) {
            reportData.forEach((ele, index) => {
              if (ele.sectionData?.length > 0) {
                const obj = [{
                  "datasourceName": `ServiceAnalysisDataSource ${index}`,
                  "tableData": ele.sectionData,
                  "sectionTitle": ele.SECTIONTITLE,
                  "sectionId": ele.SECTIONID,
                  "dateFormattingColumns" : ['CMVALUE','YTD', 'CLASSAVG'],
                  "removeLineBreak": true,
                }];

                currentTableResponse.push(obj);
              }

            });
          }
          else{
            this.noDataToDisplayMsg = REPORT_MSGS.NO_RECORDS_FOUND;
          }
        }
        this.partsServiceSalesAnalysisjsonObj.datasource = currentTableResponse;

        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }

  public getViewId(company) {
    const companyName = company?.name.toLowerCase();
    return `${this.reportTitle.replace(/\s+/g, '_').toLowerCase()}_${companyName}`;
  }

  public createPayload(searchMonth, region, district, searchYear, dealer, viewId) {
    const requestObj = {
      month: searchMonth,
      region_no: region,
      district_cd: district,
      year: searchYear,
      dealer_no: dealer,
      view_id: viewId
    }
    return requestObj;
  }

}
