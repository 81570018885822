import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './component/empty-route/empty-route.component';
import { QuicksightReportsComponent } from './component/quicksight-reports/quicksight-reports.component';
import { CustomReportDashboardComponent } from './modules/custom-reports/components/custom-report-dashboard/custom-report-dashboard.component';
import { LexusPoSnapshotComponent } from './modules/custom-reports/reports/lexus-po-snapshot/lexus-po-snapshot.component';
import { ReportGridComponent } from './modules/workout-sales/components/report-grid/report-grid.component';
import { TCUVDealerAtGlanceComponent } from './modules/custom-reports/reports/tcuv-dealer-at-glance/tcuv-dealer-at-glance.component';
import { LexusDealerAtGlanceComponent } from './modules/custom-reports/reports/lexus-dealer-at-glance/lexus-dealer-at-glance.component';
import { BulkUploadComponent } from './modules/workout-sales/components/bulk-upload/bulk-upload.component';
import { PartsAndServiceDepartmentAnalysisComponent } from './modules/custom-reports/reports/parts-and-service-department-analysis/parts-and-service-department-analysis.component';
import { LexusSalesAtGlanceComponent } from './modules/custom-reports/reports/lexus-sales-at-glance/lexus-sales-at-glance.component';
import { PartsServiceSalesAnalysisComponent } from './modules/custom-reports/reports/parts-service-sales-analysis/parts-service-sales-analysis.component';
import { ToyotaDealerAtGlanceComponent } from './modules/custom-reports/reports/toyota-dealer-at-glance/toyota-dealer-at-glance.component';
import { PandSfinancialComponent } from './modules/custom-reports/reports/P&S Financial/ps-financial.component';
import { NationalCompositeNewUsedDeptAnalysisComponent } from './modules/custom-reports/reports/national-composite-new-used-dept-analysis/national-composite-new-used-dept-analysis.component';
import { TCUVPeromanceCardComponent } from './modules/custom-reports/reports/tcuv-performance-card/tcuv-performance-card.component';
import { DealerReviewComponent } from './modules/custom-reports/reports/dealer-review/dealer-review.component';
import { DspmMonthlySummaryReport } from './modules/custom-reports/reports/dspm/dspm-monthly-summary-report.component';
import { NationalDealerPerformanceFinancialServicesComponent } from './modules/custom-reports/reports/national-dealer-performance-financial-services/national-dealer-performance-financial-services.component';
import { DealerPerformaceNewUsedCombinedFsFinComponent} from './modules/custom-reports/reports/dealer-performance-new-used-combined-fs-fin/dealer-performance-new-used-combined-fs-fin.component';

const routes: Routes = [
  {
    path: 'workout',
    children: [
      { path: 'operational', component: ReportGridComponent },
      { path: 'historical', component: ReportGridComponent },
      { path: 'details', component: QuicksightReportsComponent },
      { path: 'dashboard', component: ReportGridComponent, pathMatch: 'full' },
      { path: 'bulk-registration', component: BulkUploadComponent },
      {
        path: 'dashboard-reports', component: CustomReportDashboardComponent,
        children: [
          { path: 'lexus-po-snapshot', component: LexusPoSnapshotComponent },
          { path: 'tcuv-dealer-at-glance', component: TCUVDealerAtGlanceComponent },
          { path: 'lexus-dealer-at-glance', component: LexusDealerAtGlanceComponent },
          { path: 'lexus-sales-at-glance', component: LexusSalesAtGlanceComponent },
          { path: 'toyota-dealer-at-glance', component: ToyotaDealerAtGlanceComponent },
          { path: 'part-department-analysis', component: PartsAndServiceDepartmentAnalysisComponent },
          { path: 'service-dept-analysis', component: PartsAndServiceDepartmentAnalysisComponent },
          { path: 'sales-analysis-used-vehicle', component: PartsServiceSalesAnalysisComponent },
          { path: 'sales-analysis-new-vehicle', component: PartsServiceSalesAnalysisComponent },
          { path: 'service-analysis', component: PartsServiceSalesAnalysisComponent },
          { path: 'parts-analysis', component: PartsServiceSalesAnalysisComponent },
          { path: 'ps-financial', component: PandSfinancialComponent },
          { path: 'national-dpr', component: NationalCompositeNewUsedDeptAnalysisComponent },
          { path: 'dealer-review', component: DealerReviewComponent },
          { path: 'tfs-national-dpr',component:NationalCompositeNewUsedDeptAnalysisComponent} , 
          { path: 'tcuv-performance-card', component: TCUVPeromanceCardComponent},
          { path: 'dspm-monthly-summary-report', component: DspmMonthlySummaryReport},
          { path: 'dspm-ytd-summary', component: DspmMonthlySummaryReport},
          { path: 'dealer-performance-fs', component: NationalDealerPerformanceFinancialServicesComponent},
          { path: 'dealer-performance-used-new-combined',component: DealerPerformaceNewUsedCombinedFsFinComponent}
        ]
      },
    ]
  },
  { path: '**', component: EmptyRouteComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
