import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin, Observable, Subscription } from "rxjs";
import { HIERARCHY_TYPE, reportUrlType, REPORT_MSGS } from "src/app/constants/constants";
import { SpinnerService } from "src/app/services/spinner.service";
import { ToastService } from 'src/app/services/toast.service';
import { CustomReportsApiService } from "../../services/api/custom-report-api.service";
import { CustomReportsFiltersService } from "../../services/custom-report-filters.service";
import { CustomReportsService } from "../../services/custom-report.service";
import { getMonthAndYear, getObjFromFilteredArray } from "../../utils/common-utils";
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-tcuv-performance-card',
  templateUrl: './tcuv-performance-card.component.html',
  styleUrls: ['./tcuv-performance-card.component.scss']
})
export class TCUVPeromanceCardComponent implements OnInit {
  public tcuvPerformanceCardjsonObj;
  public subscription: Subscription = new Subscription();
  public sources: Observable<any>[] = [];
  public reportId: number;
  public reportTitleToDisplay: string;
  public noDataToDisplayMsg: string;
  selectedFilteredValues: any = [];
  previousFilter: any = [];
  reportTitle: string;
  currentTabIndex: number = 0;
  selectedTabIndex: any;
  isFilterSame: any;
  public obj = Object.values;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private customerReportService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();

    this.reportId = reportUrlType[reportTypeNameFromUrl];


    this.getTCUVPerformanceCard();
  }
  ngOnInit(): void {
    this.subscribeReportData();
  }

  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }

  public async getTabDataSource(tabIndex: number) {
    const obj = getObjFromFilteredArray(this.selectedFilteredValues);
    const dateObj = getMonthAndYear(obj['monthYear']);
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      dealer_code: obj['dealer']?.MEMBER_CODE,
      view_id: this.getViewId(tabIndex)
    }
    if (tabIndex === 0) {
      this.sources = [this.customReportsApiService.getTCUVPerformaceReportCardData(requestObj, tabIndex).pipe(this.customerReportService.handleError()?.bind(this))]
    } else if (tabIndex === 1 || tabIndex === 2 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4 || tabIndex === 5 || tabIndex === 6) {
      this.sources = [this.customReportsApiService.getTCUVPerformaceReportCardData(requestObj, tabIndex).pipe(this.customerReportService.handleError()?.bind(this))]
    }
    else {
      this.spinnerService.hideSpinner();
    }
    return this.sources
  }


  public tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    this.sources = [];
    const index = this.selectedTabIndex.findIndex(x => x === tabIndex);
    this.spinnerService.displaySpinner();
    if (index === -1 && this.selectedFilteredValues.length > 0) {
      this.selectedTabIndex.push(tabIndex);
      this.filterService.setSelectedValues(this.selectedFilteredValues);
    } else {
      this.spinnerService.hideSpinner();
    }
  }

  public subscribeReportData() {
    this.subscription.add(this.filterService.getSelectedValues().subscribe({
      next: (selectedFilter) => {
        if (selectedFilter.length > 0) {
          this.spinnerService.displaySpinner();
          this.selectedFilteredValues = selectedFilter;
          this.isFilterSame = _.isEqual(this.selectedFilteredValues, this.previousFilter);
          if (this.isFilterSame === false) {
            this.selectedTabIndex = [];
            this.selectedTabIndex.push(this.currentTabIndex);
          }
          if (this.tcuvPerformanceCardjsonObj?.tabList[this.currentTabIndex]?.datasource?.length > 0 || this.isFilterSame === false) {
            this.getTCUVPerformanceCard(); //initialise to empty object
          }
          this.mapResponse();
        } 
        else {
          this.spinnerService.hideSpinner();
        }
      }, error: (error) => {
        console.error('Error handler:', error)
        this.spinnerService.hideSpinner();
      }
    })
    )
  }

  ngOnDestroy(): void {
    this.customerReportService.setPdfExcelIcons(false);
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription.unsubscribe();
  }

  public getTCUVPerformanceCard() {
    let tabList = [
      {
        tabName: 'Summary',
        datasource: []
      },
      {
        tabName: 'Dashboard',
        datasource: []
      },
      {
        tabName: 'Advertising And Grosses',
        datasource: []
      },
      {
        tabName: 'Floor Plan And Processes',
        datasource: []
      },
      {
        tabName: 'Calendar Year Turns',
        datasource: []
      },
      {
        tabName: 'Gross ROI',
        datasource: []
      },
      {
        tabName: 'Net ROI',
        datasource: []
      }
    ];
    this.tcuvPerformanceCardjsonObj = {
      tabList: tabList
    }
  }

  public async mapResponse() {
    let currentTableResponse = [];
    await this.getTabDataSource(this.currentTabIndex);
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        this.customerReportService.setPdfExcelIcons(true);
        if (this.currentTabIndex === 0) {
          if (resp.length > 0 && resp[0].status === 200) {
            let reportData = resp[0]?.body[0]?.reportData;
            if (reportData?.length > 0) {
              reportData.forEach((ele, index) => {
                if (ele.sectionData?.length > 0) {
                  const obj = {
                    "datasourceName": `TCUVPerformanceSummaryDataSource ${index}`,
                    "tableData": ele.sectionData,
                    "sectionTitle": ele.SECTIONTITLE,
                    "sectionId": ele.SECTIONID,
                    "removeLineBreak": true,
                    "isDisplayedColumns": false,
                    "isReduceSColumnWidth": ['MEASURE_EMPTY', 'DEALERSHIP_MTD_EMPTY']
                  };
                  currentTableResponse.push(obj);
                }

              });
            }

          }
        } else if (this.currentTabIndex === 4 || this.currentTabIndex === 5 || this.currentTabIndex === 6) {
          if (resp.length > 0 && resp[0].status === 200) {
            let reportData = resp[0]?.body[0]?.reportData;
            if (reportData?.length > 0) {
              reportData.forEach((ele, index) => {
                if (ele.sectionData?.length > 0) {
                  const obj = {
                    "datasourceName": `TCUVPerformanceCalendarDataSource ${index}`,
                    "tableData": ele.sectionData,
                    "sectionTitle": ele.SECTIONTITLE,
                    "sectionId": ele.SECTIONID,
                    "removeLineBreak": true,
                    "isDisplayedColumns": false,
                    "showRowBorder": true,
                    "isReduceSColumnWidth": ['STEP', 'OPERATOR'],
                    "isAllColumnsLeftAlignment": true
                  };
                  currentTableResponse.push(obj);
                }

              });
            }

          }
        } else if (this.currentTabIndex === 1 || this.currentTabIndex === 2 || this.currentTabIndex === 3) {
          if (resp.length > 0 && resp[0].status === 200 && resp[0].body) {
            let array = ['operations', 'usedvehicleinventory'];
            array.forEach(element => {
              let reportData = resp[0].body?.[element]?.[0]?.reportData;
              if (reportData?.length > 0) {
                const displayedColumns = this.getDisplayedColumns(resp[0].body?.[element]?.[0]?.reportHeaderData[0])
                let tcuvPerformanceDashboardHeader = [{ [element.toUpperCase()]: 7 }];
                reportData.forEach((ele, index) => {
                  const DashboardSubHeader = [{
                    [ele.SECTIONTITLE]: 7,
                  }];
                  if (ele.sectionData?.length > 0) {
                    const obj = {
                      'datasourceName': `nationalDpr ${index}`,
                      'tableData': ele.sectionData,
                      'sectionTitle': ele.SECTIONTITLE,
                      'sectionId': ele.SECTIONNO,
                      'removeLineBreak': true,
                      'columnHeaderName': this.obj(displayedColumns),
                      'dateFormattingColumns': 'all',
                      "tableSubHeader": DashboardSubHeader,
                      "isReduceSColumnWidth": ['OPERTAIONS_TYPE', '/', '='],
                      "tableHeaderClass": "bg-color-yellow",
                      "tableSubHeaderBGColorClass": "bg-color-brown",
                      "tableSubHeaderWhiteBGColorClass": "bg-color-white",
                      "isAllColumnsLeftAlignment": true

                    };
                    if (index === 0) {
                      obj['tableHeader'] = tcuvPerformanceDashboardHeader;
                    }
                    currentTableResponse.push(obj)
                  }
                });
              } else {
                this.noDataToDisplayMsg = REPORT_MSGS.NO_RECORDS_FOUND;
              }

            });
          }
        }
        this.tcuvPerformanceCardjsonObj.tabList[this.currentTabIndex].datasource = currentTableResponse;

        this.previousFilter = cloneDeep(this.selectedFilteredValues);
        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }

  public getViewId(tabIndex: number) {
    const viewIds = ['summary', 'service_retention_info', '', 'volume_info', '', ''];
    return `${viewIds[tabIndex]}`;
  }
  getDisplayedColumns(reportHeaderData: any) {
    reportHeaderData['OPERTAIONS'] = '';
    reportHeaderData['GROSS_PROFIT'] = reportHeaderData.DLR_GROSS_PROFIT;
    reportHeaderData['/'] = "/";
    reportHeaderData['SALES'] = reportHeaderData.DLR_SALES;
    reportHeaderData['='] = "=",
      reportHeaderData['GPSALES'] = reportHeaderData.GP_SALES;
    reportHeaderData['DLRGUIDE'] = reportHeaderData.GUIDE;
    const data = _.pick(reportHeaderData, 'OPERTAIONS', 'GROSS_PROFIT', '/', 'SALES', '=', 'GPSALES', 'DLRGUIDE');
    return data;
  }
}  